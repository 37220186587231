<template>
  <div class="artist-container" v-loading.fullscreen.lock="loading">
    <ArtistList @viewArtist="viewArtist" :artists="artists" />
  </div>
</template>

<script>
import ArtistList from "../components/ArtistList.vue";
import { searchArtist } from "@/api/artists.api";
export default {
  name: "Artists",
  components: {
    ArtistList,
  },
  data() {
    return {
      artists: [],
      term: "",
      loading: false,
    };
  },
  async mounted() {
    if (this.$route.query.search) {
      // this.$store.dispatch(
      //   "EventsModule/searchArtist",
      //   this.$route.query.search
      // );
      await this.getArtists(this.$route.query.search);
    }
  },
  computed: {
    // searchTerm() {
    //   return this.$store.getters["EventsModule/getArtistSearchQuery"];
    // },
    searchQuery() {
      return this.$route.query.search;
    },
  },
  watch: {
    async searchQuery(newValue) {
      this.loading = true;
      await this.getArtists(newValue);
      this.loading = false;
    },
  },
  methods: {
    async getArtists(searchedArtist) {
      if (searchedArtist !== null && searchedArtist !== "") {
        try {
          this.loading = true;
          const data = await searchArtist(searchedArtist);
          this.artists = data.data;
        } catch (error) {
          console.error(error);
        }
        this.loading = false;
      } else {
        this.artists = [];
      }
    },
    viewArtist(uuid) {
      this.openArtistProfileDrawer(uuid);
    },
  },
};
</script>

<style lang="scss" scoped></style>
